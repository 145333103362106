import styled from 'styled-components';
import Animations from '../Animations';
import { AppTheme, SpacingName } from 'src/components/Theme';
import { Flex, Text, SVGIcon, Input, Scrollbar } from 'src/components';

const Option = styled.div<{
  selected?: boolean;
  highlightBackgroundOnHover?: boolean;
  highlightTextOnHover?: boolean;
  extraPaddingLeft?: boolean;
}>`
  font-size: 14px;
  line-height: 26px;

  transition: background-color 100ms ease-in-out 0s;
  padding: ${props => props.theme.spacing.xsm} ${props => props.theme.spacing.sm};
  user-select: none;
  font-weight: bold;
  ${props =>
    props.selected ? `color: ${props.theme.colors.text.onWhitePrimary};` : `color: ${props.theme.colors.text.onWhite};`}

  ${props => (props.extraPaddingLeft ? `padding-left: 50px;` : '')}

  &:hover {
    color: ${props => props.highlightTextOnHover !== false && props.theme.colors.text.onWhitePrimary};
    background-color: ${props => props.highlightBackgroundOnHover && '#F6F7F8'};
  }
`;

const IconContainer = styled.div<{ backgroundColor?: keyof AppTheme['colors']; paddingRight?: string }>`
  /* This positions the icon right in front of the input */
  position: absolute;
  margin: 0 auto;
  width: 15%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 2;
  background-color: ${props => props.backgroundColor && props.theme.colors[props.backgroundColor]};

  /* Puts the icon on the right side */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: ${props => props.paddingRight || '16px'};
  box-sizing: border-box;

  > * {
    /* set up icon animation */
    transition: transform 100ms ease-in-out, fill 100ms ease-in-out;
    fill: ${props => props.theme.colors.input.onWhite};
  }

  &:hover {
    > * {
      transform: scale(1.3);
      fill: initial;
    }
  }
`;

const ExtraSearchContainer = styled.div`
  background-color: white !important;
  width: 370px !important;
  margin-left: 2px;

  padding-bottom: 12px;

  ${Text.Link} {
    font-size: 13px;
    font-weight: 700;
  }

  ${Input.Text} {
    margin-left: 15px;
    padding: 11px;
  }
`;

export default {
  IconContainer,
  Option,
  GroupCollapseExpandButton: styled.div`
    position: absolute;
    right: 17px;
    margin-top: -34px;
    line-height: 12px;
    ${SVGIcon} {
      opacity: 0.9;
    }
    :hover {
      ${SVGIcon} {
        opacity: 1;
      }
    }

    > * {
      /* set up icon animation */
      transition: transform 100ms ease-in-out, fill 100ms ease-in-out;
    }

    &:hover {
      > * {
        transform: scale(1.15);
      }
    }
  `,
  Container: styled.div<{ disabled?: boolean; error?: boolean; inputPaddingRight?: string; inputPaddingLeft?: string }>`
    position: relative;
    cursor: pointer;

    ${props => (props.error ? `border: 1px solid ${props.theme.colors.text.onWhiteError}!important;` : '')};

    ${Input.Text} {
      position: inherit;
      padding-right: ${props => props.inputPaddingRight || props.theme.spacing.xmd};
      ${props => (props.inputPaddingLeft ? `padding-left: ${props.inputPaddingLeft};` : '')}

      &:disabled {
        /* Restore the default input css so it doesn't look like it is disabled */
        ${props => (!props.disabled ? 'opacity: 1;' : '')}
      }
    }
  `,

  NoDataContainer: styled(Flex)``,
  ExtraSearchContainer,
  LeftIconContainer: styled(IconContainer)`
    left: 16px;
    right: inherit;
    width: initial;

    &:hover {
      > * {
        transform: inherit;
        fill: initial;
      }
    }
  `,
  PlaceholderIconContainer: styled.div`
    right: 36px;
    position: absolute;
    top: 43px;
    pointer-events: none;
  `,
  ShowMoreLink: styled(Text.VisualLink)`
    font-size: 13px;
    font-weight: 700;
  `,
  RequiredMark: styled.div`
    position: absolute;
    pointer-events: none;
    font-weight: bold;
    color: ${props => props.theme.colors.text.onWhiteSelected};
    margin-top: 19px;
    margin-left: 15px;
    z-index: 2;
    font-size: 16px;
  `,
  OptionsContainer: styled(Animations.FadeDown)<{
    optionsContainerHeight?: number;
    optionsContainerWidth?: number;
    optionsContainerMaxHeight?: number;
    thinScrollbar?: boolean;
    topSpace?: SpacingName;
    roundedBorders?: boolean;
    borderWidth?: string;
  }>`
    position: absolute;
    margin: 0 auto;
    //The important is needed as at some circumstance at some environments first go these rules but later "height: 100%"" that causes issue.
    //To ensure exact height the !important is used.
    //Related issue: https://dev.azure.com/S-GEDevops/DigitaleProdukte/_workitems/edit/22025?src=WorkItemMention&src-action=artifact_link
    //The optionsContainerMaxHeight propery has a higher priority. If defined we don't want to use optionsContainerHeight
    ${props =>
      props.optionsContainerMaxHeight === undefined
        ? `height: ${props.optionsContainerHeight || 250}px!important;`
        : ''};

    width: ${props => (props.optionsContainerWidth ? '' + props.optionsContainerWidth + 'px' : '100%')}!important;
    box-sizing: border-box;
    overflow: auto;

    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.input.onWhite};
    border-top: none;
    z-index: 32;
    padding: 26px 0 12px;

    box-shadow: 5px 20px 50px 0 rgba(0, 0, 0, 0.2);

    max-height: ${props => (props.optionsContainerHeight || 225) + 'px'};
    overflow-y: auto;
    overflow-x: hidden;

    border-width: ${props => props.borderWidth};
    border-radius: ${props => props.roundedBorders && '4px'};
    margin-top: ${props => props.topSpace && props.theme.spacing[props.topSpace]};

    ${Scrollbar}
    scrollbar-width: ${props => props.thinScrollbar && 'thin'};

    ${ExtraSearchContainer} {
      ${Input.Text} {
        width: ${props => (props.optionsContainerWidth ? '' + (props.optionsContainerWidth - 37) + 'px' : undefined)};
        padding-left: 16px;
      }
    }

    ${props =>
      props.optionsContainerMaxHeight
        ? `max-height: ${props.optionsContainerMaxHeight}px; height: auto !important;`
        : ''};
  `,

  OptionsBox: styled.div`
    border-radius: 4px;
    overflow: hidden;
  `,
  OptionsTopBar: styled.div`
    height: 10px;
    background-color: transparent;
    position: absolute;
    width: 500px;
  `
};
