import styled from 'styled-components';
import { TabletAndDown } from '../stylingUtils';

export default {
  TableContainer: styled.div`
    overflow: auto;
  `,
  Table: styled.table`
    width: 100%;
    border-spacing: 0 2px;
    table-layout: fixed;

    ${props => TabletAndDown(props)} {
      table-layout: auto;
    }
  `,
  TableHeader: styled.th<{ width?: string }>`
    padding: 0 ${props => `${props.theme.spacing.sm} ${props.theme.spacing.sm}`};
    width: ${props => props.width || '100%'};
    box-sizing: border-box;
    text-align: left;

    ${props => TabletAndDown(props)} {
      width: auto;
      min-width: ${props => props.width || 'auto'};
    }

    :first-child {
      padding-left: ${props => props.theme.spacing.md};
    }
    :last-child {
      padding-right: ${props => props.theme.spacing.md};
    }
  `,
  TableRow: styled.tr<{ isSelected?: boolean }>`
    background: ${props =>
      props.isSelected
        ? 'linear-gradient(0deg, rgba(231, 187, 189, 0.25) 0%, rgba(231, 187, 189, 0.25) 100%), #FFF'
        : props.theme.colors.white};
  `,
  TableCell: styled.td<{ align?: string }>`
    padding: ${props => `${props.theme.spacing.md} ${props.theme.spacing.sm}`};
    :first-child {
      padding-left: ${props => props.theme.spacing.md};
    }
    :last-child {
      padding-right: ${props => props.theme.spacing.md};
    }
    text-align: ${props => props.align || 'left'};
  `
};
