import React, { CSSProperties, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styles from './styles';
import ChipList from '../ChipList';
import AppTheme, { TextColorName, InputColorName, SpacingName } from 'src/components/Theme';
import { useIntl } from 'react-intl';
import { useDetectClickOutside } from 'src/hooks/useDetectClickOutside';
import { doArraysContainTheSameElements } from 'src/utils/arrayUtils';
import { useField } from 'formik';
import { FormFieldError } from '../Form/components/FormFieldError';
import Hover from '../Hover';
import { Flex, Text, Spacing, Divider, SVGIcon, LocalizedText, Input } from '@components';
import { useBreakpoints } from '@utils/viewportUtils';

//An enhanced dropdown select control that allows many display and functionality customizations

export type CheckboxState = 'checked' | 'unchecked' | 'indeterminate';

export type SelectOption_v2 = {
  //A unique indentifier related to the option.
  id: number | string;
  name?: string;
  //Indicates wheather an option is a group option
  isGroup?: boolean;
  //Indicates wheather a group option is expanded
  isGroupExpanded?: boolean;
  //Options might have also any other fields that can be lates used in display/search functions for example
  [key: string]: any;
};

export type SelectOptionWithState = SelectOption_v2 & {
  state: CheckboxState;
};

//Information about empty/not empty groups is needed to show/hide group expand button
//Related request ticket: https://dev.azure.com/S-GEDevops/DigitaleProdukte/_workitems/edit/28710/
type SelectOptionWithStateAndGroupInfo = SelectOptionWithState & { isGroupEmpty?: boolean };

export type DropdownHideTrigger_v2 = 'click-outside' | 'mouse-leave';

type SelectSearchProps_v2 = {
  //All options that might be displayed in the dropdown
  options?: SelectOption_v2[];
  //A subset of above options that are selected initially
  initiallySelectedItems?: SelectOption_v2[];
  placeholder?: string;
  addRequiredMark?: boolean;
  onSelectionChange?: (selectedItems: SelectOptionWithState[]) => void;
  triggerOnSelectionChangeAlsoForGroups?: boolean;
  multiselect?: boolean;
  allowDeselection?: boolean;
  //If multiselect is true but we still want to close the dropdown on every option click
  //the flag below might be used.
  alwaysCloseDropdownOnClick?: boolean;
  alwaysCloseDropdownOnCheck?: boolean;
  displayChips?: boolean;
  sizeVariant?: 'default' | 'lg';
  //Used only for initial items selection + setSelectedItems method
  areOptionsEqual?: (newlySelectedOption: SelectOption_v2, b: SelectOption_v2) => boolean;
  searchFields?: string[];
  displayFieldOrFunction?:
    | string
    | ((item: any, index: number, state?: CheckboxState, isGroup?: boolean, isHovered?: boolean) => JSX.Element);
  disabled?: boolean;
  placeholderLeftImage?: string;
  inputPlaceholderColor?: TextColorName;
  leftImageFieldOrFunction?: string | ((item: object) => string);
  //Default: 16px
  inputFontSize?: string;
  leftImageSize?: string;
  leftImageStyle?: CSSProperties;
  leftImageSpace?: number;
  textColor?: TextColorName;
  inputBorderColor?: InputColorName;
  selectedBackgroundColor?: string;
  extraContent?: JSX.Element;
  highlightBackgroundOnHover?: boolean;
  highlightTextOnHover?: boolean;
  optionsContainerHeight?: number;
  optionsContainerMaxHeight?: number;
  optionsContainerWidth?: number;
  //Localizations
  selectAllTranslationKey?: string;
  resetTranslationKey?: string;
  separatorIndices?: number[];
  dataHjAllow?: boolean;
  dataTestId?: string;
  downArrowIcon?: string;
  upArrowIcon?: string;
  thinScrollbar?: boolean;
  //Default: true
  selectDeselectOnGroupClick?: boolean;
  //In case set to true there is no option>group dependency with selection. Group can be freely selected by the user.
  //Additionally the dropDownDisplayFunction gets as an argument also groups, not only leaf options
  //Default: false
  considerGroupsAsNormalOptions?: boolean;
  //Default: true
  extraPaddingForSuboptions?: boolean;
  customOptionOnClickLogic?: (context: {
    clickedOption: SelectOptionWithState;
    groupOption2SubOptions: (groupOption: SelectOptionWithState, onlyDisplayed?: boolean) => SelectOptionWithState[];
    options: SelectOptionWithState[];
    selectOptions: (optionsToBeSelected: SelectOptionWithState[], deselectAllOthers?: boolean) => void;
    subOption2groupOption: (subOption: SelectOptionWithState) => SelectOptionWithState | null;
    toggleOptionSelection: (option: SelectOptionWithState) => void;
  }) => 'prevent-default' | void;
  fullWidth?: boolean;
  //While using groups showSelectedOnTop is not supported
  showSelectedOnTop?: boolean;
  itemsLimit?: number;
  closeOn?: DropdownHideTrigger_v2;
  highlightOnSelection?: boolean;
  //Once changing filter text, this flag prevents from hidding already selected items from the list.
  //For longer lists it improves UX - user can use the text filter multiple times.
  alwaysShowAlreadySelectedItems?: boolean;
  deselectOnFilterTextChange?: boolean;
  expandGroupsOnProvidedFilterText?: boolean;
  dropDownDisplayFunction?: (selectedItems: object[]) => string;
  enableUserFiltering: boolean;
  clearFiterTextOnShow?: boolean;
  groupArrow?: {
    style?: object;
    expandIcon?: string;
    collapseIcon?: string;
  };
  extraSearchInput?: {
    //Shows an extra search input box.
    //In case it is set to true, the standard input is not in use anylonger and could be eventually replaced with Text node
    show?: boolean;
    showSelectAllButton?: boolean;
    showResetButton?: boolean;
    showSearch?: boolean;
    selectAllButtonTranslationKey?: boolean;
    hideDropdownOnSelectAllClick?: boolean;
    deselectAllOnSelectAllClick?: boolean;
    clearFilterTextOnResetPress?: boolean;
    resetButtonTranslationKey?: boolean;
    placeholderIcon?: string;
    placeholderTranslationKey?: string;
  };
  optionsFrameConfiguration?: {
    topSpace?: SpacingName;
    roundedBorders?: boolean;
    borderWidth?: string;
  };
  chipsDisplayField?: string;
  //Formik
  name?: string;
  errorPrefix?: string;
  initialOptionsFilterText?: string;
  onOptionsFilterTextChange?: (newText: string) => void;
  //Default: true
  showOptionsContainerOnNoOptions?: boolean;
  //Default: false
  searchableGroupNames?: boolean;
  //In case is set to true, the paddings and other spacings gets reduced
  tightInputTextMode?: string;
};

const SelectSearch_v2 = forwardRef((props: SelectSearchProps_v2, ref) => {
  //Formik. hacky condition to be able to use SelectSearch also out of a Formik context.
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = props.name ? useField(props.name || 'no-field') : [null, { error: false, touched: false }];
  const hasError = !!meta.error && !!meta.touched;

  const intl = useIntl();
  const [optionsHidden, setOptionsHidden] = useState(true);
  const { tabletAndDown } = useBreakpoints();
  const [currentImage, setCurrentImage] = useState('');
  const [optionsFilterText, setOptionsFilterText] = useState(props.initialOptionsFilterText || '');
  const extraSearchInputRef = useRef<HTMLInputElement | null>(null);

  const areOptionsEqual =
    props.areOptionsEqual ||
    ((a: SelectOption_v2, b: SelectOption_v2) => {
      return String(a['id']) === String(b['id']);
    });

  const getInitialOptions = (selection: SelectOption_v2[] | undefined) => {
    return (props.options || []).map(option => {
      const isAmongInputOptions = !!(
        selection?.findIndex(newlySelectedOption => areOptionsEqual(newlySelectedOption, option)) != -1
      );
      return {
        ...option,
        state: isAmongInputOptions ? 'checked' : 'unchecked'
      } as SelectOptionWithState;
    });
  };

  //List of all options with correctly set state based on initiallySelectedItems list
  const [options, setOptions] = useState(getInitialOptions(props.initiallySelectedItems || []));

  const setNewOptions = (newOptions: SelectOptionWithState[]) => {
    if (props.triggerOnSelectionChangeAlsoForGroups) {
      const currentSelectedOptions = options.filter(option => option.state == 'checked');
      const newSelectedOptions = newOptions.filter(option => option.state == 'checked');
      const areEqual = areOptionSetsEqual(currentSelectedOptions, newSelectedOptions);
      setOptions(newOptions);
      if (!areEqual) {
        onSelectionChange(newSelectedOptions);
      }
    } else {
      const currentSelectedOptionsNoGroups = options.filter(option => !option.isGroup && option.state == 'checked');
      const newSelectedOptionsNoGroups = newOptions.filter(option => !option.isGroup && option.state == 'checked');
      const areEqual = areOptionSetsEqual(currentSelectedOptionsNoGroups, newSelectedOptionsNoGroups);
      setOptions(newOptions);
      if (!areEqual) {
        onSelectionChange(newSelectedOptionsNoGroups);
      }
    }
  };

  const [optionId2IsVisible, setOptionId2IsVisible] = useState({} as { [key: number]: boolean });
  const [optionsToBeDisplayed, setOptionsToBeDisplayed] = useState<SelectOptionWithStateAndGroupInfo[]>(options);
  const [currentRenderLimit, setCurrentRenderLimit] = useState(props.itemsLimit);

  const displayFieldOrFunction = props.displayFieldOrFunction;
  const showLeftImage = currentImage != '';
  const groupOptions = props?.options?.filter(option => option?.isGroup == true) || [];
  const containsGroups = groupOptions.length > 0;

  const allSelected = options.filter(option => option.state == 'checked');
  const allSelectedNotGroups = allSelected.filter(option => !option.isGroup);
  const option2Index = (option: SelectOptionWithState) => options.findIndex(x => x.id == option.id);

  //We store list of on top options in a separate state because we don't want
  //the selected options goes up right after option toggle.
  const [onTopOptionIds, setOnTopOptionIds] = useState(allSelectedNotGroups.map(option => option.id));

  //In case expandGroupsOnProvidedFilterText is set to true and the current input filter text is not empty we show
  //all options + hide expand/collapse buttons as they are not relevant in that case.
  const allGroupsExpandedAndExpandMarksHidden =
    props.expandGroupsOnProvidedFilterText && optionsFilterText.trim() != '';

  const outisideClickRef = useDetectClickOutside({
    onTriggered:
      props.closeOn == 'click-outside'
        ? () => {
            setOptionsHidden(true);
          }
        : undefined
  });

  useImperativeHandle(ref, () => ({
    resetSelection() {
      deselectAllOptions();
    },
    setSelectedItems(selectedItems: SelectOption_v2[] | undefined) {
      setNewOptions(getInitialOptions(selectedItems));
    },
    closeDropdown() {
      setOptionsHidden(true);
    },
    openDropdown() {
      setOptionsHidden(false);
    }
  }));

  const areOptionSetsEqual = (optionsA: object[], optionsB: object[]) => {
    return doArraysContainTheSameElements(optionsA, optionsB, (a, b) => {
      return a.id == b.id;
    });
  };

  const doesOptionMatchFilterText = (
    option: SelectOptionWithState,
    exactLowerCaseMatch?: boolean,
    filterOutOnlyEmptyNotMatchingGroups?: boolean,
    nextOption?: SelectOptionWithState
  ) => {
    if (filterOutOnlyEmptyNotMatchingGroups) {
      if (option.isGroup) {
        const isEmptyGroup = !nextOption || nextOption?.isGroup;
        if (!isEmptyGroup) {
          //We don't check not empty groups
          return true;
        }
      } else {
        //We don't check options
        return true;
      }
    } else {
      //We don't check groups
      if (option.isGroup) {
        return true;
      }
    }

    const fieldsToCheck = props.searchFields || ['name'];

    for (let i = 0; i < fieldsToCheck.length; i++) {
      const field = fieldsToCheck[i];
      if (exactLowerCaseMatch) {
        if (option[field].toLocaleLowerCase() == optionsFilterText.toLocaleLowerCase()) return true;
      } else {
        if (option[field].toLocaleLowerCase().indexOf(optionsFilterText.toLocaleLowerCase()) > -1) return true;
      }
    }
    return false;
  };

  const filterOutEmptyGroups = (options: SelectOptionWithState[]) => {
    const result: SelectOptionWithState[] = [];
    for (let i = 0; i < options.length; i++) {
      const currentOption = options[i];
      const nextOption = options[i + 1];
      if (!((nextOption == null && currentOption.isGroup) || (currentOption.isGroup && nextOption.isGroup))) {
        result.push(currentOption);
      }
    }

    return result;
  };

  //This function might also modify the internal state of options setting isEmptyGroup accordingly
  const filterOutOptionsInCollapsedGroups = (options: SelectOptionWithState[]) => {
    const result: SelectOptionWithStateAndGroupInfo[] = [];
    let isCurrentGroupExapanded = true;
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (option.isGroup) {
        const nextOption = options[i + 1];
        isCurrentGroupExapanded = allGroupsExpandedAndExpandMarksHidden || option.isGroupExpanded || false;
        option.isGroupEmpty = !nextOption || nextOption.isGroup;
        result.push(option);
      } else {
        if (isCurrentGroupExapanded !== false) {
          result.push(option);
        }
      }
    }
    return result;
  };

  const getFilteredOptionsByMatchingText = (
    options: SelectOptionWithState[],
    filterOutOnlyEmptyNotMatchingGroups?: boolean
  ) => {
    let options_ = [];
    if (optionsFilterText && optionsFilterText.length > 0) {
      options_ = [
        ...((options &&
          options.filter((option, index) => {
            const nextOption = options[index + 1];
            return doesOptionMatchFilterText(option, undefined, filterOutOnlyEmptyNotMatchingGroups, nextOption);
          })) ||
          [])
      ];
    } else {
      options_ = options || [];
    }
    return options_;
  };

  //gets filtered options by text
  //If includeOptionsInCollapsedGroups set to true it returns list of options also inside of groups that are collapsed
  const getFilteredOptions = () => {
    //
    let filteredOptions = getFilteredOptionsByMatchingText(options);

    //Filtering by group
    //In case filtering text is not provided we allow for empty groups
    filteredOptions =
      optionsFilterText && optionsFilterText.length > 0 && !props.searchableGroupNames
        ? filterOutEmptyGroups(filteredOptions)
        : filteredOptions;

    //Filtering out empty groups that doesn't match the search text
    if (props.searchableGroupNames && optionsFilterText && optionsFilterText.length > 0) {
      filteredOptions = getFilteredOptionsByMatchingText(filteredOptions, true);
    }

    return filteredOptions;
  };

  useEffect(() => {
    if (!optionsHidden && props.clearFiterTextOnShow) {
      setOptionsFilterText('');
    }

    if (optionsHidden && props.itemsLimit !== undefined) {
      setCurrentRenderLimit(props.itemsLimit);
    }

    setOnTopOptionIds(allSelectedNotGroups.map(option => option.id));

    if (!optionsHidden && props.extraSearchInput?.show && !tabletAndDown) {
      extraSearchInputRef?.current?.focus();
    }
  }, [optionsHidden]);

  useEffect(() => {
    const map = {};

    optionsToBeDisplayed.forEach(option => {
      if (option.id !== undefined) map[option.id] = true;
    });

    setOptionId2IsVisible(map);
  }, [optionsToBeDisplayed]);

  useEffect(() => {
    updateGroupOptionsStatus();
  }, [optionId2IsVisible]);

  useEffect(() => {
    updateOptionsToBeDisplayed();
  }, [options]);

  const getSelected = () => {
    //|| props.considerGroupsAsNormalOptions === true
    return options.filter(option => option.state == 'checked');
  };

  const getSelectedButGroups = () => {
    //|| props.considerGroupsAsNormalOptions === true
    return options.filter(option => option.state == 'checked' && !option.isGroup);
  };

  const onSelectionChange = (options_: SelectOptionWithState[]) => {
    if (props.onSelectionChange) {
      props.onSelectionChange(options_);
    }
    updateGroupOptionsStatus();
  };

  const updateOptionsToBeDisplayed = () => {
    if (props.enableUserFiltering) {
      const filteredOptions = getFilteredOptions();
      //Filtered out options in collapsed groups
      const filteredOptionsWithoutOptionsInCollapsedGroups = filterOutOptionsInCollapsedGroups(filteredOptions);

      //Sorting
      const sortedAndFilteredOptions =
        //While using groups showSelectedOnTop is not supported
        props.showSelectedOnTop && !containsGroups
          ? [
              ...filteredOptionsWithoutOptionsInCollapsedGroups.filter(item => onTopOptionIds.indexOf(item.id) !== -1),
              ...filteredOptionsWithoutOptionsInCollapsedGroups.filter(item => onTopOptionIds.indexOf(item.id) === -1)
            ]
          : filteredOptionsWithoutOptionsInCollapsedGroups;

      setOptionsToBeDisplayed(sortedAndFilteredOptions);
    } else {
      setOptionsToBeDisplayed(options);
    }
  };

  //Updating options to be displayed - filtering + sorting
  useEffect(() => {
    updateOptionsToBeDisplayed();
  }, [optionsHidden, optionsFilterText]);

  useEffect(() => {
    props.onOptionsFilterTextChange?.(optionsFilterText);
  }, [optionsFilterText]);

  const groupOption2SubOptions = (groupOption: SelectOptionWithState, onlyDisplayed?: boolean) => {
    const result: SelectOptionWithState[] = [];
    const groupOptionIndex = option2Index(groupOption);

    for (let i = groupOptionIndex + 1; i < options.length; i++) {
      const option = options[i];
      if (option.isGroup) {
        return result;
      } else {
        if (!onlyDisplayed || (option.id !== undefined && optionId2IsVisible[option.id])) {
          result.push(option);
        }
      }
    }

    return result;
  };

  const subOption2groupOption = (subOption: SelectOptionWithState) => {
    const optionIndex = option2Index(subOption);

    for (let i = optionIndex; i >= 0; i--) {
      const option = options[i];
      if (option.isGroup) {
        return option;
      }
    }

    return null;
  };

  const updateGroupOptionsStatus = () => {
    //In case we allow user for group selections independently from sub-options there is no need to
    //update group state depending on internal selections
    if (props.considerGroupsAsNormalOptions === true) {
      return;
    }
    if (containsGroups) {
      //Setting group option accordingly
      const groupOptions = options.filter(option => option.isGroup);

      groupOptions.forEach(groupOption => {
        if (groupOption.id !== undefined && optionId2IsVisible[groupOption.id]) {
          const subOptions = groupOption2SubOptions(groupOption, false);
          const amountOfSubOptionsSelected = subOptions.filter(option_ => option_.state == 'checked').length;
          const areAllSelected = amountOfSubOptionsSelected == subOptions.length;
          const noneSelected = amountOfSubOptionsSelected == 0;

          if (!(noneSelected || areAllSelected)) {
            //Some selected
            indetermineOption(groupOption);
          } else {
            if (areAllSelected) {
              selectOption(groupOption);
            } else {
              deselectOption(groupOption);
            }
          }
        }
      });
    }
  };

  const onOptionClick = (option: SelectOptionWithState) => {
    if (props.customOptionOnClickLogic) {
      if (
        props.customOptionOnClickLogic({
          clickedOption: option,
          options,
          toggleOptionSelection,
          subOption2groupOption,
          groupOption2SubOptions,
          selectOptions
        }) == 'prevent-default'
      ) {
        return;
      }
    }

    const wasOptionChecked = option.state !== 'checked';

    if (props.multiselect || (props.allowDeselection && option.state == 'checked')) {
      toggleOptionSelection(option);
    } else {
      selectOption(option, true);
    }

    if (
      (!props.multiselect && !props.allowDeselection) ||
      props.alwaysCloseDropdownOnClick ||
      (props.alwaysCloseDropdownOnCheck && wasOptionChecked)
    )
      setOptionsHidden(true);
  };

  const onGroupClick = (option: SelectOptionWithState) => {
    if (props.considerGroupsAsNormalOptions === true) {
      onOptionClick(option);
      return;
    }

    const allSubOptions = groupOption2SubOptions(option);
    const matchinTextOptions = getFilteredOptionsByMatchingText(options);
    const optionId2IsMatching = {} as { [key: number]: boolean };

    matchinTextOptions.forEach(option => {
      if (option.id !== undefined) optionId2IsMatching[option.id] = true;
    });

    //For collapsed we select just matching

    if (props.selectDeselectOnGroupClick === false) {
      //Just expanding/collapsing
      if (option.isGroupExpanded) {
        option.isGroupExpanded = false;
      } else {
        option.isGroupExpanded = true;
      }
      updateOptionsToBeDisplayed();
    } else {
      if (option.state == 'unchecked') {
        option.isGroupExpanded = true;
        selectOptions([...allSubOptions.filter(opt => opt.id !== undefined && optionId2IsMatching[opt.id]), option]);
        updateOptionsToBeDisplayed();
      } else {
        deselectOptions([...allSubOptions, option]);
      }
    }
  };

  const setNewOptionState = (optionIds: (string | number)[], newState: CheckboxState) => {
    setNewOptions(
      options.map(option => {
        if (optionIds.indexOf(option.id) !== -1) {
          return { ...option, state: newState };
        }
        return { ...option };
      })
    );
  };

  const setNewOptionsStates = (checkedOptionIds: (string | number)[], uncheckedOptionIds: (string | number)[]) => {
    setNewOptions(
      options.map(option => {
        if (checkedOptionIds.indexOf(option.id) !== -1) {
          return { ...option, state: 'checked' };
        } else if (uncheckedOptionIds.indexOf(option.id) !== -1) {
          return { ...option, state: 'unchecked' };
        }
        return { ...option };
      })
    );
  };

  const toggleOptionSelection = (option: SelectOptionWithState) => {
    setNewOptionState([option.id], option.state == 'checked' ? 'unchecked' : 'checked');
  };

  const deselectOption = (option: SelectOptionWithState) => {
    if (option.state != 'unchecked') {
      setNewOptionState([option.id], 'unchecked');
    }
  };

  const selectOption = (option: SelectOptionWithState, deselectAllOthers?: boolean) => {
    if (!deselectAllOthers) {
      if (option.state != 'checked') {
        setNewOptionState([option.id], 'checked');
      }
    } else {
      const allIdsButToBeSelected = options2Ids(options.filter(opt => opt.id != option.id));
      setNewOptionsStates([option.id], allIdsButToBeSelected);
    }
  };

  const indetermineOption = (option: SelectOptionWithState) => {
    if (option.state != 'indeterminate') {
      setNewOptionState([option.id], 'indeterminate');
    }
  };

  const options2Ids = (options: SelectOptionWithState[]) => {
    return options.map(option => option.id);
  };

  const selectOptions = (optionsToBeSelected: SelectOptionWithState[], deselectAllOthers?: boolean) => {
    if (!deselectAllOthers) {
      const optionsIds = options2Ids(optionsToBeSelected);
      setNewOptionState(optionsIds, 'checked');
    } else {
      const idsTobeSelected = optionsToBeSelected.map(item => item.id);
      const allIdsButToBeSelected = options2Ids(options.filter(opt => idsTobeSelected.indexOf(opt.id) === -1));
      setNewOptionsStates(
        optionsToBeSelected.map(item => item.id),
        allIdsButToBeSelected
      );
    }
  };

  const deselectOptions = (optionsToBeDeselected: SelectOptionWithState[]) => {
    const optionsIds = options2Ids(optionsToBeDeselected);
    setNewOptionState(optionsIds, 'unchecked');
  };

  const deselectAllOptions = () => {
    const optionsIds = options2Ids(options);
    setNewOptionState(optionsIds, 'unchecked');
  };

  /**
   * Function takes an input and filters the results based on the user's input
   * @param event React.ChangeEvent<HTMLInputElement>
   */
  const onSearchInputTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event && event.target && event.target.value) || '';

    setOptionsFilterText(value);
    setOptionsHidden(false);

    if (props.deselectOnFilterTextChange !== false) {
      deselectAllOptions();
    }
  };

  /**
   * Function checks if the user presses the Escape key to reset the search
   * @param event React.KeyboardEvent<HTMLInputElement>
   */
  const onInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = (event && event.key) || null;
    if (key && key === 'Escape') {
      setOptionsFilterText('');
      deselectAllOptions();
    }
    if (key && key === 'Enter') {
      const results = options.filter(i => doesOptionMatchFilterText(i, true)) || [];
      if (results && results.length > 0) {
        setText(results);
        selectOptions(results);
        setOptionsHidden(true);
      } else {
        deselectAllOptions();
      }
    }
  };

  const setImage = (newSelected?: SelectOptionWithState[]) => {
    if (newSelected && newSelected instanceof Array && newSelected.length > 0) {
      setCurrentImage(
        props.leftImageFieldOrFunction
          ? typeof props.leftImageFieldOrFunction == 'function'
            ? props.leftImageFieldOrFunction(newSelected[0])
            : newSelected[0][props.leftImageFieldOrFunction]
          : ''
      );
    }
  };

  const setText = (newSelected?: SelectOptionWithState[]) => {
    if (newSelected && newSelected instanceof Array && newSelected.length > 0) {
      if (props.searchFields?.length && !props.clearFiterTextOnShow) {
        setOptionsFilterText(newSelected[0][props.searchFields[0]]);
      }
    }
  };

  useEffect(() => {
    if (allSelected && allSelected instanceof Array && allSelected.length > 0) {
      if (!props.extraSearchInput?.show) {
        setText(allSelected);
      }
      setImage(allSelected);
    }
  }, [allSelected]);

  const inputProps = {
    ...(props.dataHjAllow && { ['data-hj-allow']: true })
  };

  const variantStyle = props.sizeVariant == 'lg' ? { padding: '23px' } : {};
  const selectedBackgroundColor = props.selectedBackgroundColor || AppTheme.colors.background;
  const normalBackgroundColor = AppTheme.colors.white;
  const highlightedDropdown =
    props.highlightOnSelection &&
    (props.considerGroupsAsNormalOptions === true ? allSelected : allSelectedNotGroups).length > 0;
  const interactiveInput = !(props.extraSearchInput?.show || !props.enableUserFiltering);
  const isResetOrSelectAllDisplayed =
    props.extraSearchInput?.showSelectAllButton !== false || props.extraSearchInput?.showResetButton !== false;

  const selected = props.considerGroupsAsNormalOptions === true ? getSelected() : getSelectedButGroups();
  const dropdownValue = props?.dropDownDisplayFunction
    ? ((selectedOptions: SelectOptionWithState[]) =>
        !selectedOptions.length
          ? props.extraSearchInput?.show
            ? //In case props.extraSearchInput?.show the input field is not used so we just display dropdown control with empty array
              props?.dropDownDisplayFunction([])
            : //To allow user providing custom text into the input field
              optionsFilterText
          : props?.dropDownDisplayFunction(selectedOptions))(selected)
    : optionsFilterText;

  const displayedRequiredMark = !selected.length && props.addRequiredMark;
  const areSomeOptionsToBeDisplayed = optionsToBeDisplayed && optionsToBeDisplayed.length > 0;

  return (
    <div style={props.fullWidth ? { width: '100%' } : {}}>
      <styles.Container
        inputPaddingRight={props.tightInputTextMode ? '0px' : undefined}
        inputPaddingLeft={props.tightInputTextMode ? '11px' : undefined}
        onMouseLeave={(props.closeOn || 'mouse-leave') == 'mouse-leave' ? () => setOptionsHidden(true) : undefined}
        disabled={props.disabled}
        ref={outisideClickRef}
        data-test-id={props.dataTestId}
      >
        {displayedRequiredMark && <styles.RequiredMark>*</styles.RequiredMark>}
        <Input.Text
          error={hasError}
          className={hasError ? 'has-error' : undefined}
          onClick={() => setOptionsHidden(!optionsHidden)}
          placeholder={displayedRequiredMark ? `\xA0\xA0\xA0${props.placeholder}` : props.placeholder}
          onKeyUp={onInputKeyUp}
          placeholderColor={props.inputPlaceholderColor}
          value={displayedRequiredMark && !!dropdownValue?.trim() ? `\xA0\xA0\xA0${dropdownValue}` : dropdownValue}
          onChange={onSearchInputTextChange}
          paddingLeft={showLeftImage ? 'lgxx' : false}
          fontSize={props.inputFontSize || '16px'}
          placeholderSize={props.inputFontSize || '16px'}
          textColor={props.textColor}
          borderColor={!optionsHidden ? 'black' : props.inputBorderColor}
          //To be customized if needed
          style={
            highlightedDropdown
              ? { backgroundColor: selectedBackgroundColor, fontWeight: 700, ...variantStyle }
              : { ...variantStyle }
          }
          readOnly={!interactiveInput}
          interactiveCursor={!interactiveInput}
          borderColorOnFocus={!props.extraSearchInput?.show}
          autoComplete="off"
          {...inputProps}
        />
        <styles.IconContainer
          onClick={() => setOptionsHidden(!optionsHidden)}
          paddingRight={props.tightInputTextMode ? '12px' : undefined}
        >
          <img
            style={
              highlightedDropdown
                ? {
                    backgroundColor: selectedBackgroundColor,
                    boxShadow: '0px 0px 0px 4px ' + selectedBackgroundColor
                  }
                : {
                    backgroundColor: normalBackgroundColor,
                    boxShadow: '0px 0px 0px 4px ' + normalBackgroundColor
                  }
            }
            src={
              props.upArrowIcon && !optionsHidden
                ? props.upArrowIcon
                : props.downArrowIcon || '/static/icons/semiArrowDownTinyGray.svg'
            }
          />
        </styles.IconContainer>
        {showLeftImage && (
          <styles.LeftIconContainer onClick={() => setOptionsHidden(!optionsHidden)}>
            <SVGIcon style={props.leftImageStyle} size={props.leftImageSize || '24px'} src={currentImage} />
          </styles.LeftIconContainer>
        )}
        {!optionsHidden && (
          <styles.OptionsBox>
            <styles.OptionsTopBar></styles.OptionsTopBar>
            {(areSomeOptionsToBeDisplayed || props.showOptionsContainerOnNoOptions !== false) && (
              <styles.OptionsContainer
                optionsContainerWidth={props.optionsContainerWidth}
                optionsContainerHeight={props.optionsContainerHeight}
                optionsContainerMaxHeight={props.optionsContainerMaxHeight}
                thinScrollbar={props.thinScrollbar}
                seconds={0.2}
                {...props.optionsFrameConfiguration}
              >
                {props.extraSearchInput?.show && (
                  <styles.ExtraSearchContainer>
                    <Flex vertical gap="sm">
                      {props.extraSearchInput?.showSearch && (
                        <>
                          {props.extraSearchInput.placeholderIcon && optionsFilterText == '' && (
                            <styles.PlaceholderIconContainer>
                              <SVGIcon src={props.extraSearchInput.placeholderIcon} size="16px" />
                            </styles.PlaceholderIconContainer>
                          )}

                          <Input.Text
                            ref={extraSearchInputRef}
                            placeholder={
                              (props.extraSearchInput.placeholderIcon ? '' : '') +
                              intl.formatMessage({
                                id: props.extraSearchInput.placeholderTranslationKey
                              })
                            }
                            onKeyUp={onInputKeyUp}
                            value={optionsFilterText}
                            onChange={onSearchInputTextChange}
                            fontSize={props.inputFontSize || '16px'}
                            placeholderSize={props.inputFontSize || '16px'}
                            textColor={props.textColor}
                            borderColor={props.inputBorderColor}
                            autoComplete="off"
                            {...inputProps}
                          />
                        </>
                      )}

                      {isResetOrSelectAllDisplayed && (
                        <Flex paddingLeft="sm" gap="xsm">
                          {props.extraSearchInput?.showSelectAllButton !== false && (
                            <>
                              <Text.Link
                                highlightedByDefault={true}
                                disabled={allSelected.length == optionsToBeDisplayed.length}
                                onClick={() => {
                                  if (props.extraSearchInput?.deselectAllOnSelectAllClick) {
                                    deselectAllOptions();
                                  } else {
                                    //Selecting only currently visible items
                                    const filteredOptions = getFilteredOptions();
                                    selectOptions(filteredOptions);
                                  }

                                  if (props.extraSearchInput?.hideDropdownOnSelectAllClick) {
                                    setOptionsHidden(true);
                                  }
                                }}
                              >
                                <LocalizedText
                                  id={props.selectAllTranslationKey || 'controls.selectSearch.selectAll'}
                                />
                              </Text.Link>
                              <Text.Link highlightedByDefault={true}>&#8226;</Text.Link>
                            </>
                          )}

                          <Text.Link
                            highlightedByDefault={true}
                            disabled={allSelected.length == 0 && optionsFilterText == ''}
                            onClick={() => {
                              deselectAllOptions();
                              if (props.extraSearchInput?.clearFilterTextOnResetPress) {
                                setOptionsFilterText('');
                              }
                            }}
                          >
                            <LocalizedText id={props.resetTranslationKey || 'controls.selectSearch.reset'} />
                          </Text.Link>
                        </Flex>
                      )}
                    </Flex>
                  </styles.ExtraSearchContainer>
                )}

                {optionsToBeDisplayed && optionsToBeDisplayed.length > 0 ? (
                  optionsToBeDisplayed.map((option, index) => {
                    const state = option.state;
                    return currentRenderLimit === undefined || index < currentRenderLimit ? (
                      <div key={index}>
                        <Hover
                          render={({ isHovered, ...otherProps }) => (
                            <styles.Option
                              {...otherProps}
                              extraPaddingLeft={
                                containsGroups && !option.isGroup && props.extraPaddingForSuboptions !== false
                              }
                              onClick={() => {
                                if (option.isGroup) {
                                  onGroupClick(option);
                                } else {
                                  onOptionClick(option);
                                }
                              }}
                              selected={state == 'checked'}
                              highlightBackgroundOnHover={props.highlightBackgroundOnHover}
                              highlightTextOnHover={props.highlightTextOnHover}
                              data-analytics-id={props['data-analytics-id']}
                            >
                              {/* {state}{' '} */}
                              {typeof displayFieldOrFunction == 'function'
                                ? displayFieldOrFunction(option, index, state, option.isGroup, isHovered)
                                : option[displayFieldOrFunction as string]}
                              {option.isGroup && !allGroupsExpandedAndExpandMarksHidden && !option.isGroupEmpty && (
                                <styles.GroupCollapseExpandButton>
                                  <SVGIcon
                                    size={'21px'}
                                    style={props.groupArrow?.style ? props.groupArrow?.style : {}}
                                    onClick={e => {
                                      //setSourceOptions
                                      options.forEach(option_ => {
                                        option.isGroupExpanded =
                                          option == option_ ? !option.isGroupExpanded : option.isGroupExpanded;
                                      });
                                      setNewOptions([...options]);
                                      //To update options display

                                      e.stopPropagation();
                                    }}
                                    src={
                                      option.isGroupExpanded
                                        ? props.groupArrow?.collapseIcon || '/static/icons/semiArrowUpTiny.svg'
                                        : props.groupArrow?.expandIcon || '/static/icons/semiArrowDownTiny.svg'
                                    }
                                  ></SVGIcon>
                                </styles.GroupCollapseExpandButton>
                              )}
                            </styles.Option>
                          )}
                        />

                        {props.separatorIndices?.includes(index) && (
                          <Divider color="lightGraySecondary" marginBottom="xsm" marginTop="xsm" />
                        )}
                      </div>
                    ) : (
                      <></>
                    );
                  })
                ) : (
                  <styles.NoDataContainer vertical full alignItems="center" justifyContent="center" padding="default">
                    <SVGIcon src="/static/icons/listLight.svg" marginBottom="sm" />
                    <Text.SmallParagraph color="onWhiteLight">
                      <LocalizedText id="noData" description="noData" />
                    </Text.SmallParagraph>
                  </styles.NoDataContainer>
                )}
                {props.extraContent}
                {currentRenderLimit !== undefined && optionsToBeDisplayed.length > currentRenderLimit && (
                  <styles.ShowMoreLink
                    onClick={() => {
                      setCurrentRenderLimit(currentRenderLimit + (props.itemsLimit || 0));
                    }}
                  >
                    <Spacing padding="sm" paddingBottom="xsm" paddingLeft="lgx">
                      <LocalizedText id="components.dropdown.showMore" />
                    </Spacing>
                  </styles.ShowMoreLink>
                )}
              </styles.OptionsContainer>
            )}
          </styles.OptionsBox>
        )}
        {props.name && optionsHidden && (
          <FormFieldError fieldName={props.name} errorPrefix={props.errorPrefix} variant="absolute-small-spacing" />
        )}
      </styles.Container>
      {props.displayChips && props.chipsDisplayField && (
        <Spacing paddingTop="xsm">
          <ChipList
            isFromSelect
            disabled={props.disabled}
            field={props.chipsDisplayField}
            chips={allSelected}
            onChipClick={chip => {
              toggleOptionSelection(chip as SelectOptionWithState);
            }}
          />
        </Spacing>
      )}
    </div>
  );
});

export default SelectSearch_v2;
